/* eslint-disable react-hooks/exhaustive-deps */
import './SearchCategory.css';
import React, {useEffect, useRef, useState} from "react";

interface SearchCategoryProps {
  title: string;
}

interface SelectSearchProps extends SearchCategoryProps {
  items: (string)[];
  withFilter?: boolean;
  selectedItems?: (items: string[]) => unknown;
}

const SelectSearch: React.FunctionComponent<SelectSearchProps> = (props: SelectSearchProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<boolean[]>(Array(props.items.length).fill(false));
  const [searchFilteredItems, setSearchFilteredItems] = useState<([string, boolean])[]>(props.items.map(i => [i, true]));
  useEffect(() => {
    setSearchFilteredItems(props.items.map(item => item.indexOf(filter) !== -1 ? [item, true] : [item, false]));
  }, [setSearchFilteredItems, props.items, filter]);

  const selectedItemsListener = props.selectedItems;
  const originalItems = props.items;

  const updateCallback = () => {
    selectedItemsListener && selectedItemsListener((selectedItems.map((item, index) => item ? originalItems[index] : undefined).filter(i => i !== undefined)) as string[]);
  }
  useEffect(updateCallback, [selectedItems]);

  return (
    <div className={`SelectSearch SearchCategory__each ${isOpen ? 'open' : ''}`}>
      <div className={"SearchCategory__each-title"} onClick={() => setIsOpen(!isOpen)}>
        {props.title}
      </div>
      <div className={"SearchCategory__each-body"}>
        {props.withFilter === true ?
          <input type={"text"} value={filter} onChange={(e) => setFilter(e.target.value)}/> : null}
        <div className={"SearchResults"}>
          {searchFilteredItems.map(([item, show], index) => (
            <label htmlFor={`SelectCategory-${props.title.toUpperCase()}-${index}`} style={{ display: show ? 'block' : 'none' }}>
              <div className={"SearchResults__each"}>
                {item}
                <input
                  type={"checkbox"}
                  id={`SelectCategory-${props.title.toUpperCase()}-${index}`}
                  data-type={props.title.toUpperCase()}
                  data-value={item}
                  onChange={(e) => setSelectedItems(selectedItems.map((i, index) => index === props.items.indexOf(item) ? e.target.checked : i))}
                  checked={selectedItems[props.items.indexOf(item)]}
                />
              </div>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}

interface RangeSearchProps extends SearchCategoryProps {
  range?: (min: number, max: number) => unknown;
}

const RangeSearch: React.FunctionComponent<RangeSearchProps> = (props: RangeSearchProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [minPrice, setMinPrice] = useState<string>('0');
  const [maxPrice, setMaxPrice] = useState<string>('0');

  const prevMinPrice = useRef<string>();
  const prevMaxPrice = useRef<string>();

  const RangeChangeListener = props.range;

  useEffect(() => {
    setMinPrice(minPrice.replace(/[^0-9.]+/g, ''));
    setMaxPrice(maxPrice.replace(/[^0-9.]+/g, ''));

    if (isNaN(parseFloat(minPrice))) {
      setMinPrice('0');
    } else if (isNaN(parseFloat(maxPrice))) {
      setMaxPrice('0');
    }

    if (parseFloat(minPrice) < 0) {
      setMinPrice('0');
    }

    prevMinPrice.current = minPrice;
    prevMaxPrice.current = maxPrice;
  }, [minPrice, maxPrice]);

  return (
    <div className={`RangeSearch SearchCategory__each ${isOpen ? 'open' : ''}`}>
      <div className={"SearchCategory__each-title"} onClick={() => setIsOpen(!isOpen)}>
        {props.title}
      </div>
      <div className={"SearchCategory__each-body"}>
        <input
          type={"text"}
          inputMode={"numeric"}
          value={minPrice}
          id={`Range_${props.title.toUpperCase()}_min`}
          onChange={e => setMinPrice(e.target.value)}
        />
        &nbsp;~&nbsp;
        <input
          type={"text"}
          inputMode={"numeric"}
          value={maxPrice}
          id={`Range_${props.title.toUpperCase()}_max`}
          onChange={e => setMaxPrice(e.target.value)}
        />
        <button
          onClick={() => RangeChangeListener && RangeChangeListener(parseFloat(minPrice), parseFloat(maxPrice))}>검색
        </button>
        <button style={{ 'display': 'none' }} id={`Range_${props.title.toUpperCase()}_remove`} onClick={() => {
          setMinPrice('0');
          setMaxPrice('0');
          RangeChangeListener && RangeChangeListener(0, 0);
        }}>Remove</button>
      </div>
    </div>
  );
};

interface SearchCategoryP {
  tagsCallback: (tags: {
    tagType: 'PRICE_RANGE' | 'FLOOR' | 'LAND' | 'TYPE';
    value: { min: number; max: number } | number | string;
  }[]) => unknown;
}

let tags: {
  tagType: 'PRICE_RANGE' | 'FLOOR' | 'LAND' | 'TYPE';
  value: { min: number; max: number } | number | string;
}[] = [];

const tagRemover = (tag: {
  tagType: 'PRICE_RANGE' | 'FLOOR' | 'LAND' | 'TYPE';
  value: { min: number; max: number } | number | string;
}) => {
  console.log(tag);
  if (tag.tagType === 'PRICE_RANGE') {
    (document.querySelector('#Range_PRICE_remove') as HTMLButtonElement).click();
  } else {
    const checkbox = document.querySelector(`input[type=checkbox][data-type="${tag.tagType}"][data-value="${tag.value}"]`);
    if (checkbox) {
      (checkbox as HTMLInputElement).click();
    }
  }
}

const SearchCategory: React.FunctionComponent<SearchCategoryP> = (props: SearchCategoryP) => {
  const PriceRangeChanged = (min: number, max: number) => {
    if (min > max) {
      alert('최소 가격은 최대 가격보다 작아야 합니다.');
      return;
    }

    if (min === 0 && max === 0) {
      tags = tags.filter((tag) => tag.tagType !== 'PRICE_RANGE');
    } else {
      const existingTag = tags.filter(tag => tag.tagType === 'PRICE_RANGE');
      if (existingTag.length < 1) {
        tags = [...tags, {
          tagType: 'PRICE_RANGE',
          value: {
            min,
            max,
          },
        }];
      } else {
        tags = tags.map(tag => tag.tagType === 'PRICE_RANGE' ? {
          tagType: 'PRICE_RANGE' as const,
          value: {
            min,
            max,
          }
        } : tag);
      }
    }

    props.tagsCallback(tags.sort((a, b) => a.tagType.localeCompare(b.tagType)));
  }

  const SetSelect = (type: 'FLOOR' | 'LAND' | 'TYPE', value: string[]) => {
    for (const item of value) {
      const existingItem = tags.find(tag => tag.tagType === type && tag.value === item);
      if (!existingItem) {
        tags.push({
          tagType: type,
          value: item,
        });
      }
    }

    const newTags = [...tags.filter(tag => tag.tagType !== type)];
    for (const tag of tags.filter(tag => tag.tagType === type)) {
      const notExistence = value.find(i => i === tag.value);
      if (notExistence) {
        newTags.push(tag);
      }
    }
    tags = newTags;

    props.tagsCallback(tags.sort((a, b) => a.tagType.localeCompare(b.tagType)));
  }

  return (
    <div className={"SearchCategory"}>
      <RangeSearch title={"Price"} range={PriceRangeChanged}/>
      <SelectSearch
        title={"Floor"}
        items={Array(100).fill(0).map((n, i) => (i + 1).toString())}
        withFilter={true}
        selectedItems={(items) => SetSelect('FLOOR', items)}
      />
      <SelectSearch
        title={"Land"}
        items={Array(100).fill(0).map((n, i) => (i + 1).toString())}
        withFilter={true}
        selectedItems={(items) => SetSelect('LAND', items)}
      />
      <SelectSearch
        title={"Type"}
        items={['Arcade', 'Apartment']}
        selectedItems={(items) => SetSelect('TYPE', items)}
      />
    </div>
  );
};

export { tagRemover };
export default SearchCategory;
