import './Header.css';
import Logo from "../Logo";
import {Link} from "react-router-dom";
import {getCookie, removeCookie} from "../../Util/Cookie";
import {useEffect, useState} from "react";
import config from "../../config";

const Header = () => {
  const token = getCookie('token');
  const [isLogin, setIsLogin] = useState<false | string>(false);
  const [point, setPoint] = useState<number>(0);

  useEffect(() => {
    const doLogin = async () => {
      const request = await fetch(`${config.SERVER_ADDR}/auth/info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await request.json();

      if (json.result) {
        setIsLogin(json.info.nickname);
        setPoint(json.info.point);
      } else {
        removeCookie('token');
      }
    };

    if (token !== undefined) {
      doLogin().then();
    }
  }, [token]);

  return (
    <div className={"Header"}>
      <div className={"Header__container"}>
        <Link to={"/"}><div className={"Header__container-logo"}><Logo /></div></Link>
        <ul className={"Header__container-menu"}>
          {isLogin ? (
            <>
              <li>
                <Link to={"/mypage"}>마이페이지</Link>
              </li>
              <li>
                잔여 캐시 {point.toLocaleString()}원
              </li>
            </>
          ) : null}
          <li>
            {isLogin
              ? <button onClick={() => {removeCookie('token'); setTimeout(() => window.location.replace('/'), 10) }} className={"btnLogin login"}>{isLogin} <div className={"ProfileImage"}><img /></div></button>
              : <Link to={"/login"}><button className={"btnLogin"}>로그인</button></Link>}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Header;
