import './style/Login.css';

import Google from '../assets/Google_Logo.png';
import Logo from '../Component/Logo';
import {useGoogleLogin} from '@react-oauth/google';
import config from "../config";
import {getCookie, setCookie} from "../Util/Cookie";
import {Navigate} from "react-router-dom";

const Login = () => {
  const googleLogin = useGoogleLogin({
    onSuccess: tokenResponse => doLogin(tokenResponse.access_token),
    onError: error => console.log(error),
  });

  const doLogin = async (accessToken: string) => {
    const request = await fetch(config.SERVER_ADDR + '/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: accessToken }),
    });
    const json = await request.json();

    if (json.result) {
      setCookie('token', json.token, 1);
      window.location.href = '/';
    } else {
      alert('로그인 중 오류가 발생했습니다.');
    }
  }

  if (getCookie('token') !== undefined) {
    return <Navigate to={"/"} />;
  }

  return (
    <div className={"LoginContainer"}>
      <Logo />
      <div className={"LoginContainer__body"}>
        <h2>Log in</h2>
        <p>구글 계정을 통해 로그인이 가능합니다.</p>
        <button className={"btnGoogleLogin"} onClick={() => googleLogin()}>
          <img src={Google} alt={"Google"} />
          Google 로그인
        </button>
      </div>
    </div>
  );
};

export default Login;
