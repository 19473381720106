import {useEffect, useState} from "react";

import './Deposit.css';
import {getCookie} from "../../Util/Cookie";
import config from "../../config";

const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return `${year}년 ${month}월 ${day}일 ${hour}시 ${minute}분 ${second}초`;
}

const Deposit = () => {
    const [deposits, setDeposits] = useState<any[]>([]);
    const [depositAmount, setDepositAmount] = useState<string>('0');

    useEffect(() => {
        const token = getCookie('token');
        if (!token) {
            window.location.replace('/login');
            return;
        }

        const getDeposit = async () => {
            const request = await fetch(`${config.SERVER_ADDR}/auth/cash/deposit`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const json = await request.json();

            if (json.result) {
                setDeposits(json.deposits);
            } else {
                alert('입금 기록을 불러오는 중 오류가 발생했습니다.');
            }
        }
        getDeposit().then();
    }, []);

    const requestDeposit = async () => {
        const token = getCookie('token');
        if (!token) {
            window.location.replace('/login');
            return;
        }

        const request = await fetch(`${config.SERVER_ADDR}/auth/cash/deposit`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                amount: depositAmount,
            }),
        });
        const response = await request.json();

        if (response.result) {
            alert('입금 요청이 완료되었습니다.');
            window.location.reload();
        }
    }

    return (
        <>
            <div className={"MyInfo-section__header"}>캐시 입금</div>
            <div className={"MyInfo-section"}>
                <table>
                    <tr>
                        <td style={{ width: '80px' }}>입금계좌</td>
                        <td>우리 1005-304-470528 대한민국청소년기자단(주)</td>
                    </tr>
                    <tr>
                        <td>입금금액</td>
                        <td><input type={"text"} value={depositAmount} onChange={(e) => setDepositAmount(e.target.value)} />원</td>
                    </tr>
                </table>
                <div style={{ textAlign: 'center' }}>
                    <button className={"MyInfo-btn"} onClick={() => requestDeposit()}>입금</button>
                </div>
                <div style={{ fontSize: '13px', marginTop: '15px' }}>
                    • 캐시 입금 관련 유의사항<br />
                    1. 캐시 입금 시 입금자명은 반드시 마이페이지에 등록한 닉네임과 동일해야 입금 상태가 자동 확인됩니다. 닉네임과 입금자명이 동일하지 않으면 입금 상태가 자동으로 확인되지 않으니 꼭 기억해주세요.<br />
                    [예시] 마이페이지에 설정한 닉네임이 ‘클레이타워’일 경우, 입금하실 때 송금자 이름을 ‘클레이타워’로 변경 후에 송금<br />
                    2. 입금자명 입력 실수 등으로 캐시를 입금했지만 입금 상태가 대기에서 완료로 바뀌지 않는다면, 홈페이지 맨 아래의 문의번호로 전화해서 수동 확인을 요청해주세요.<br />
                    3. 클레이타워 캐시는 청약철회가 불가능한 상품으로 환불되지 않습니다. 서비스 이용약관은 홈페이지 하단 메뉴를 참조하세요.
                </div>
            </div>
            <div className={"MyInfo-section__header"}>캐시 입금 기록</div>
            <div className={"MyInfo-section"} style={{ textAlign: 'center' }}>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>입금일시</td>
                            <td>입금금액</td>
                            <td>상태</td>
                        </tr>
                    </thead>
                    <tbody>
                        {deposits.map((deposit, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{formatDate(new Date(deposit.requested_time))}</td>
                                <td>{deposit.amount.toLocaleString()}원</td>
                                <td>{deposit.status === 0 ? '대기' : deposit.status === 1 ? '완료' : '거절'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Deposit;
