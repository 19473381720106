import {useEffect, useState} from "react";
import './MyLands.css';
import config from "../../config";
import {getCookie} from "../../Util/Cookie";
import {Link} from "react-router-dom";

const MyLands = () => {
  const [myLands, setMyLands] = useState<{
    id: number;
    image: string;
    price: number;
    nft_id: string;
    floor: number;
    land: number;
    type: string;
    lease_duration: number;
  }[]>([]);

  useEffect(() => {
    const doSearch = async () => {
      const request = await fetch(`${config.SERVER_ADDR}/land/my_lands`, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        }
      });
      const json = await request.json();

      if (json.result) {
        setMyLands(json.lands);
      } else {
        alert('임대 랜드 정보를 불러오는 중 오류가 발생했습니다.');
      }
    };

    doSearch().then();
  }, []);

  const removeLand = async (landId: number) => {
    if (window.confirm('삭제 후엔 복구가 불가능합니다. 삭제하시겠습니까?')) {
      const request = await fetch(`${config.SERVER_ADDR}/land/${landId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        }
      });
      const json = await request.json();

      if (json.result) {
        window.location.reload();
      } else {
        alert('임대 대기 랜드를 삭제하는 중 오류가 발생했습니다.');
      }
    }
  }

  return (
    <div className={"MyLands"}>
      <div className={"MyLands__title"}>임대 랜드</div>
      <div className={"MyLands__lands"}>
        {myLands && myLands.map((land) => (
          <div className={"MyLands__land"}>
            <div className={"MyLands__land-image"}><img src={land.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')} alt={'랜드 이미지'} /></div>
            <div className={"MyLands__land-info"}>
              <div className={"MyLands__land-title"}>
                {land.type === 'Arcade' ? '상가' : '아파트'} {land.floor}층 {land.land}호
              </div>
              <div className={"MyLands__land-duration"}>{Math.floor(land.lease_duration)}개월</div>
              <div className={"MyLands__land-price"}>{land.price.toLocaleString()}원</div>
            </div>
            <div className={"Submenu"}>⋮
              <div className={"Dropdown"}>
                {/*<div className={"Dropdown__item"}>수정하기</div>*/}
                <div className={"Dropdown__item"} onClick={() => removeLand(land.id)}>삭제하기</div>
              </div>
            </div>
          </div>
        ))}
        <Link to={"/land/new"}>
          <div style={{ padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
            <div style={{ fontSize: '200%' }}>+</div>
            <div style={{ color: '#333' }}>임대 등록하기</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MyLands;
