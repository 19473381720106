import {useEffect, useState} from "react";
import './LeasedLands.css';
import config from "../../config";
import {getCookie} from "../../Util/Cookie";

const LeasedLands = () => {
  const [leasedLands, setLeasedLands] = useState<{
    id: number;
    nickname: string;
    image: string;
    price: number;
    nft_id: string;
    floor: number;
    land: number;
    type: string;
    lease_duration: number;
    created: string;
  }[]>([]);

  useEffect(() => {
    const doSearch = async () => {
      const request = await fetch(`${config.SERVER_ADDR}/land/leased_lands`, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        }
      });
      const json = await request.json();

      if (json.result) {
        setLeasedLands(json.lands);
      } else {
        alert('임차 랜드 정보를 불러오는 중 오류가 발생했습니다.');
      }
    };

    doSearch().then();
  }, []);

  return (
    <div className={"LeasedLands"}>
      <div className={"LeasedLands__title"}>임차 랜드</div>
      <div className={"LeasedLands__lands"}>
        {leasedLands && leasedLands.map((land) => (
          <div className={"LeasedLands__land"}>
            <div className={"LeasedLands__land-image"}><img src={land.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')} alt={'랜드 이미지'} /></div>
            <div className={"LeasedLands__land-info"}>
              <div className={"LeasedLands__land-title"}>
                {land.type === 'Arcade' ? '상가' : '아파트'} {land.floor}층 {land.land}호
              </div>
              <div className={"LeasedLands__land-duration"}>{Math.floor(land.lease_duration)}개월</div>
              <div className={"LeasedLands__land-owner"}>{land.nickname}</div>
              <div className={"LeasedLands__land-price"}>{land.price.toLocaleString()}원</div>
              <div className={"LeasedLands__land-created"}>{new Date(land.created).getFullYear()}. {(new Date(land.created).getMonth() + 1).toString().padStart(2, '0')}. {new Date(land.created).getDate().toString().padStart(2, '0')}</div>
            </div>
          </div>
        ))}
        {leasedLands.length === 0 ? (
          <div style={{ marginTop: '5px' }}>아직 임차한 랜드가 없습니다.</div>
        ) : null}
      </div>
    </div>
  );
};

export default LeasedLands;
