import './App.css';

import {BrowserRouter, Routes, Route} from "react-router-dom";
import Login from "./View/Login";
import Main from "./View/Main";
import {GoogleOAuthProvider} from '@react-oauth/google';
import Land from "./View/Land";
import Mypage from "./View/Mypage";
import NewLand from "./View/NewLand";
import {useEffect, useState} from "react";
import config from "./config";
import Search from "./View/Search";

const App = () => {
    const [footer, setFooter] = useState('');
    useEffect(() => {
        const loadFooter = async () => {
            const request = await fetch(`${config.SERVER_ADDR}/auth/footer`);
            const response = await request.json();

            setFooter(response.footer);
        }

        loadFooter().then();
    }, []);

    return (
        <BrowserRouter>
            <GoogleOAuthProvider clientId='230743055159-rn179i3esio1mqfgnl20u7ngvbg5uojb.apps.googleusercontent.com'>
                <div style={{
                    minHeight: 'calc(100vh - 100px)',
                    marginBottom: '80px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Routes>
                        <Route path={"/"} element={<Main/>} />
                        <Route path={"/login"} element={<Login/>} />
                        <Route path={"/land/new"} element={<NewLand/>} />
                        <Route path={"/land/:landId"} element={<Land/>} />
                        <Route path={"/mypage/*"} element={<Mypage/>} />
                        <Route path={"/search"} element={<Search />} />
                    </Routes>
                </div>
                <footer>
                    <div className={"container"}>
                        <div className={"policy_area"}>
                            <a href={"http://klaytower.com/terms_of_use"} target={"_blank"} rel="noreferrer">서비스
                                이용약관</a>
                            <a href={"http://klaytower.com/privacy_policy"} target={"_blank"}
                               rel="noreferrer">개인정보처리방침</a>
                        </div>
                        <div className={"business_area"}>
                            {footer.split('\n').map((line) => <>{line}<br /></>)}
                        </div>
                    </div>
                </footer>
            </GoogleOAuthProvider>
        </BrowserRouter>
    );
}

export default App;
