import './style/Main.css';
import Header from "../Component/Header";
import SearchCategory, {tagRemover} from "../Component/SearchCategory";
import {useEffect, useState} from "react";
import config from "../config";
import {Link} from "react-router-dom";
import RecentLease from "../Component/RecentLease";

const Main = () => {
  const [tags, setTags] = useState<{
    tagType: 'PRICE_RANGE' | 'FLOOR' | 'LAND' | 'TYPE';
    value: { min: number; max: number } | number | string;
  }[]>([]);
  const [lands, setLands] = useState<{
    id: number;
    floor: number;
    land: number;
    lease_duration: number;
    image: string;
    nft_id: string;
    nickname: string;
    price: number;
    type: string;
  }[]>([]);
  const [page, setPage] = useState(1);

  const tagToString = (tag: {
    tagType: 'PRICE_RANGE' | 'FLOOR' | 'LAND' | 'TYPE';
    value: { min: number; max: number } | number | string;
  }) => {
    if (tag.tagType === 'PRICE_RANGE' && typeof tag.value === 'object') {
      return `${tag.value.min.toLocaleString()}~${tag.value.max.toLocaleString()}`;
    } else if (tag.tagType === 'FLOOR') {
      return `${tag.value}F`;
    }

    return `${tag.value}`;
  }

  const resetTag = async () => {
    const tags = Array.from(document.querySelectorAll('.Tag')) as HTMLDivElement[];

    for (const tag of tags) {
      tag.click();
      await (() => new Promise(resolve => { setTimeout(resolve); }));
    }
  }

  useEffect(() => {
    const doSearch = async () => {
      const filter: Record<string, unknown> = {};
      const priceRange = tags.find((tag) => tag.tagType === 'PRICE_RANGE');
      if (priceRange) {
        filter['price'] = priceRange.value;
      }
      filter['floor'] = tags.filter((tag) => tag.tagType === 'FLOOR').map((tag) => tag.value);
      filter['land'] = tags.filter((tag) => tag.tagType === 'LAND').map((tag) => tag.value);
      filter['type'] = tags.filter((tag) => tag.tagType === 'TYPE').map((tag) => tag.value);

      const request = await fetch(`${config.SERVER_ADDR}/land`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filter,
        }),
      });
      const json = await request.json();

      if (json.result) {
        setLands(json.data);
      } else {
        alert('랜드 정보를 불러오는 중 오류가 발생했습니다.');
      }
    };
    doSearch().then();
  }, [tags]);

  return (
    <>
      <Header />
      <div>
        <div className={"RecentHeader"}>
          <div className={"container"}>
            최근 임대차 실거래
          </div>
        </div>
        <div className={"container"}>
          <RecentLease />
        </div>
      </div>
      <div className={"Main container"}>
        <div className={"Main__search"}>
          <SearchCategory tagsCallback={(tags) => setTags(tags)} />
          <div className={"TagsContainer"}>
            {tags.map(tag => <div className={"Tag"} onClick={() => tagRemover(tag)}>{tagToString(tag)}</div>)}
            <div className={"TagsReset__container"}><button onClick={() => resetTag()} className={"TagsReset"}>Reset</button></div>
          </div>
        </div>
        <div className={"Main__lands"}>
          {lands.filter((land, index) => index >= ((page - 1) * 48) && index < (page * 48)).map(land => (
            <Link to={`/land/${land.id}`}>
              <div className={"Land"}>
                <div className={"Land__image"}><img src={land.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')} alt={'랜드 이미지'} /></div>
                <div className={"Land__info_wrapper"}>
                  <div className={"Land__nickname"}>{land.nickname}</div>
                  <div className={"Land__info"}>{land.type === 'Arcade' ? '상가' : '아파트'} {land.floor}층 {land.land}호</div>
                  <div className={"Land__duration"}>{Math.floor(land.lease_duration)}개월</div>
                  <div className={"Land__price"}>{land.price.toLocaleString()}원</div>
                </div>
              </div>
            </Link>
          ))}
          {lands.length === 0 ? (
            <div style={{ textAlign: 'center', marginTop: '60px' }}>
              검색 결과가 없습니다.
            </div>
          ) : null}
        </div>
        <div className={"Main__pagination"}>
          {Array.from(Array(Math.ceil(lands.length / 48)).keys()).map((page) => (
            <div className={"Page"} onClick={() => setPage(page + 1)}>{page + 1}</div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Main;
