import {useEffect, useState} from "react";

import './Withdraw.css';
import {getCookie} from "../../Util/Cookie";
import config from "../../config";

const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return `${year}년 ${month}월 ${day}일 ${hour}시 ${minute}분 ${second}초`;
}

const Withdraw = () => {
    const [withdraws, setWithdraws] = useState<any[]>([]);
    const [withdrawAmount, setWithdrawAmount] = useState<string>('0');

    useEffect(() => {
        const token = getCookie('token');
        if (!token) {
            window.location.replace('/login');
            return;
        }

        const getWithdraw = async () => {
            const request = await fetch(`${config.SERVER_ADDR}/auth/cash/withdraw`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const json = await request.json();

            if (json.result) {
                setWithdraws(json.withdraws);
            } else {
                alert('출금 기록을 불러오는 중 오류가 발생했습니다.');
            }
        }
        getWithdraw().then();
    }, []);

    const requestWithdraw = async () => {
        const token = getCookie('token');
        if (!token) {
            window.location.replace('/login');
            return;
        }

        const request = await fetch(`${config.SERVER_ADDR}/auth/cash/withdraw`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                amount: withdrawAmount,
            }),
        });
        const response = await request.json();

        if (response.result) {
            alert('출금 요청이 완료되었습니다.');
            window.location.reload();
        }
    }

    return (
        <>
            <div className={"MyInfo-section__header"}>캐시 출금</div>
            <div className={"MyInfo-section"}>
                <table>
                    <tr>
                        <td>출금금액</td>
                        <td><input type={"text"} value={withdrawAmount} onChange={(e) => setWithdrawAmount(e.target.value)} />원</td>
                    </tr>
                </table>
                <div style={{ textAlign: 'center' }}>
                    <button className={"MyInfo-btn"} onClick={() => requestWithdraw()}>출금</button>
                </div>
                <div style={{ fontSize: '13px', marginTop: '15px' }}>
                    • 캐시 출금 관련 유의사항<br />
                    1. 캐시 출금 기능은 랜드 호실 소유권을 가진 유저를 위한 기능으로 출금 신청일로부터 영업일 기준 최대 3~5일 소요될 수 있습니다.<br />
                    2. 출금 신청 후 1주일이 지났는데 출금이 정상적으로 확인되지 않는다면, 홈페이지 맨 아래의 문의번호로 전화해서 서비스 담당자에게 알려주시면 됩니다.
                </div>
            </div>
            <div className={"MyInfo-section__header"}>캐시 출금 기록</div>
            <div className={"MyInfo-section"} style={{ textAlign: 'center' }}>
                <table style={{ width: '100%' }}>
                    <thead>
                    <tr>
                        <td>#</td>
                        <td>출금일시</td>
                        <td>출금금액</td>
                        <td>상태</td>
                    </tr>
                    </thead>
                    <tbody>
                    {withdraws.map((withdraw, index) => (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{formatDate(new Date(withdraw.requested_time))}</td>
                            <td>{withdraw.amount.toLocaleString()}원</td>
                            <td>{withdraw.status === 0 ? '대기' : withdraw.status === 1 ? '완료' : '거절'}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Withdraw;
