import './style/Search.css';
import Header from "../Component/Header";

const Search = () => {
  return (
    <>
      <Header />
      <div style={{ textAlign: 'center' }}>
          <div className={"SearchContainer"}>
              <input type={"text"} />
              <button>검색</button>
          </div>
      </div>
    </>
  );
};

export default Search;
