import './Logo.css';

const Logo = () => {
  return (
    <div className={"KlaytowerLogo"}>
      <h2>Klaytower</h2>
      <h1>마켓플레이스</h1>
    </div>
  );
}

export default Logo;
