import Header from "../Component/Header";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import config from "../config";

import './style/Land.css';
import {getCookie} from "../Util/Cookie";

const Land = () => {
  const { landId } = useParams();

  const [land, setLand] = useState<{
    id: number;
    floor: number;
    land: number;
    lease_duration: number;
    image: string;
    nft_id: string;
    nickname: string;
    price: number;
    type: string;
  } | undefined>(undefined);

  useEffect(() => {
    const getLand = async () => {
      const request = await fetch(`${config.SERVER_ADDR}/land/${landId}`);
      const json = await request.json();

      if (json.result) {
        setLand(json.land);
      } else {
        alert('랜드 정보를 불러오는 중 오류가 발생했습니다.');
      }
    };

    getLand().then();
  }, [landId]);


  const checkEligibility = async () => {
    const token = getCookie('token');

    if (!token) {
      window.location.replace('/');
      return;
    }

    const request = await fetch(`${config.SERVER_ADDR}/auth/myinfo`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await request.json();

    if (!json.result || !json.info.bank_account || !json.info.bank_name || !json.info.bank_owner || !json.info.phone) {
      alert('랜드 임대를 시작하기 위해서 개인정보를 모두 입력해 주세요.');
      window.location.replace('/mypage/info');
      return false;
    }
    return true;
  }

  const doLease = async () => {
    if (!(await checkEligibility())) {
      return;
    }

    if (getCookie('token') === undefined) {
      window.location.replace('/login');
      return;
    }

    const request = await fetch(`${config.SERVER_ADDR}/land/${landId}/lease`, {
      headers: {
        Authorization: `Bearer ${getCookie('token')}`,
      },
    });
    const json = await request.json();

    if (json.result) {
      alert('랜드 임대가 완료되었습니다.');
      window.location.replace('/mypage');
    } else {
      switch (json.message) {
        case 'You cannot lease your own land':
          alert('자기 자신의 랜드를 임대할 수 없습니다.')
          break;
        case 'Already leased':
          alert('이미 임대된 랜드입니다.');
          break;
        case 'Not enough point':
          alert('임대에 필요한 캐시가 부족합니다.');
          break;
        default:
          alert('랜드 임대 중 오류가 발생했습니다.');
      }
    }
  }

  return (
    <>
      <Header />
      <div className={"LandDetail container"}>
        <div className={"LandDetail__image"}>
          <img src={land?.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')} alt={'랜드 이미지'} />
        </div>
        <div className={"LandDetail__info"}>
          {land ? (
            <>
              <div className={"LandDetail__info-title"}>
                {land.type === 'Arcade' ? '상가' : '아파트'} {land.floor}층 {land.land}호
              </div>
              <div className={"LandDetail__info-duration"}>{Math.floor(land.lease_duration)}개월</div>
              <div className={"LandDetail__info-owner"}>
                <div className={"ProfileImage"}><img /></div> {land.nickname}
              </div>
              <div className={"LandDetail__info-price"}>{land.price.toLocaleString()}원</div>
            </>
          ) : null}
        </div>
        <div className={"LandDetail__button_wrapper"}>
          <button className={"LandDetail__button"} onClick={() => doLease()}>구매하기</button>
        </div>
      </div>
    </>
  );
};
export default Land;
