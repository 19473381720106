import config from "../../config";
import {getCookie} from "../../Util/Cookie";

import './Kaikas.css';
import KlaytnLogo from '../../assets/Klaytn_Logo.svg';
import {useEffect, useState} from "react";

const Kaikas = () => {
  const [wallet, setWallet] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getInfo = async () => {
      const request = await fetch(`${config.SERVER_ADDR}/auth/info`, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
      });
      const json = await request.json();

      if (json.result && json.info.wallet_address) {
        setWallet(json.info.wallet_address);
      }
    };

    getInfo().then();
  }, []);

  const enableKaikas = async () => {
    if ('klaytn' in window) {
      // @ts-ignore
      const provider = window.klaytn;

      await provider.enable();
      const address = provider.selectedAddress;

      // @ts-ignore
      const caver = window.caver;
      const signedMessage = await caver.klay.sign('This signature is used to authenticate your Klaytn wallet to the Klaytower Marketplace. If you didn\'t ask for it yourself, never approve it.', address);

      const request = await fetch(`${config.SERVER_ADDR}/auth/kaikas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('token')}`,
        },
        body: JSON.stringify({
          wallet: address,
          sign: signedMessage,
        }),
      });
      const json = await request.json();

      if (json.result) {
        window.location.reload();
      } else {
        alert('Kaikas 지갑 연동에 실패했습니다. 다시 시도해 주세요.');
      }
    } else {
      alert('Kaikas 지갑이 설치되어 있지 않거나 활성화되지 않았습니다.');
    }
  }

  if (wallet) {
    return (
      <button className={"KaikasButton"} style={{ color: 'black' }}>
        {wallet.substring(0, 10)}...{wallet.substring(wallet.length - 10)}
      </button>
    );
  } else {
    return (
      <button className={"KaikasButton"} onClick={() => enableKaikas()}>
        <img src={KlaytnLogo} alt={"Klaytn"} />
        Klaytn
      </button>
    );
  }
}

export default Kaikas;
