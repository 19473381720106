import {useEffect, useState} from "react";
import config from "../../config";
import {getCookie} from "../../Util/Cookie";
import './LeaseHistory.css';

const LeaseHistory = () => {
  const [showLease, setShowLease] = useState<number>(3);
  const [showLeased, setShowLeased] = useState<number>(3);

  const [leaseHistory, setLeaseHistory] = useState<{
    lease: {
      id: number;
      floor: string;
      type: string;
      land: string;
      price: number;
      image: string;
      lease_duration: number;
      nickname: string;
      created: string;
    }[];
    leased: {
      id: number;
      floor: string;
      type: string;
      land: string;
      price: number;
      image: string;
      lease_duration: number;
      nickname: string;
      created: string;
    }[];
  }>({
    lease: [],
    leased: [],
  });

  useEffect(() => {
    const getHistory = async () => {
      const request = await fetch(`${config.SERVER_ADDR}/land/history`, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
      });
      const json = await request.json();

      if (json.result) {
        setLeaseHistory({
          ...json,
          result: undefined,
        });
      } else {
        alert('내역을 불러오는 중 오류가 발생했습니다.')
      }
    }
    getHistory().then();
  }, []);

  const formatDuration = (leaseDuration: number, created: string) => {
    const s = new Date(created);
    const c = new Date(created);

    c.setMonth(c.getMonth() + leaseDuration);

    return `${s.getFullYear()}. ${(s.getMonth() + 1).toString().padStart(2, '0')}. ${s.getDate().toString().padStart(2, '0')} ~ ${c.getFullYear()}. ${(c.getMonth() + 1).toString().padStart(2, '0')}. ${c.getDate().toString().padStart(2, '0')}`;
  }


  return (
    <>
      <div className={"LeasedLands"} style={{ marginBottom: '80px' }}>
        <div className={"LeasedLands__title"}>임대 내역</div>
        <div className={"LeasedLands__lands"}>
          {leaseHistory.lease && leaseHistory.lease.filter((l, i) => i < showLease).map((land) => (
            <div className={"LeasedLands__land"}>
              <div className={"LeasedLands__land-image"}><img src={land.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')} alt={'랜드 이미지'} /></div>
              <div className={"LeasedLands__land-info"}>
                <div className={"LeasedLands__land-title"}>
                  {land.type === 'Arcade' ? '상가' : '아파트'} {land.floor}층 {land.land}호
                </div>
                <div className={"LeasedLands__land-duration"}>{Math.floor(land.lease_duration)}개월</div>
                <div className={"LeasedLands__land-owner"}>{land.nickname}</div>
                <div className={"LeasedLands__land-price"}>{land.price.toLocaleString()}원</div>
                <div className={"LeasedLands__land-created"}>{formatDuration(land.lease_duration, land.created)}</div>
              </div>
            </div>
          ))}
          {leaseHistory.lease.length === 0 ? (
            <div style={{ marginTop: '5px' }}>아직 임대한 랜드가 없습니다.</div>
          ) : null}
          {leaseHistory.lease.length > showLeased ? (
            <div className={"LeasedLands__more"} onClick={() => setShowLease(showLeased + 3)}>더보기</div>
          ) : undefined}
        </div>
      </div>
      <div className={"LeasedLands"}>
        <div className={"LeasedLands__title"}>임차 내역</div>
        <div className={"LeasedLands__lands"}>
          {leaseHistory.leased && leaseHistory.leased.filter((l, i) => i < showLeased).map((land) => (
            <div className={"LeasedLands__land"}>
              <div className={"LeasedLands__land-image"}><img src={land.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')} alt={'랜드 이미지'} /></div>
              <div className={"LeasedLands__land-info"}>
                <div className={"LeasedLands__land-title"}>
                  {land.type === 'Arcade' ? '상가' : '아파트'} {land.floor}층 {land.land}호
                </div>
                <div className={"LeasedLands__land-duration"}>{Math.floor(land.lease_duration)}개월</div>
                <div className={"LeasedLands__land-owner"}>{land.nickname}</div>
                <div className={"LeasedLands__land-price"}>{land.price.toLocaleString()}원</div>
                <div className={"LeasedLands__land-created"}>{formatDuration(land.lease_duration, land.created)}</div>
              </div>
            </div>
          ))}
          {leaseHistory.leased.length === 0 ? (
            <div style={{ marginTop: '5px' }}>아직 임차한 랜드가 없습니다.</div>
          ) : null}
          {leaseHistory.leased.length > showLeased ? (
            <div className={"LeasedLands__more"} onClick={() => setShowLeased(showLeased + 3)}>더보기</div>          ) : undefined}
        </div>
      </div>
    </>
  )
}
export default LeaseHistory;
