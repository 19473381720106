import {useEffect, useState} from "react";
import Kaikas from "../Kaikas";

import './MyInfo.css';
import {getCookie} from "../../Util/Cookie";
import config from "../../config";

const MyInfo = () => {
  const [myInfo, setMyInfo] = useState<{
    nickname: string;
    wallet_address: string;
    bank_name: string;
    bank_owner: string;
    bank_account: string;
    phone: string;
    ssn: string | null;
    brand_verification_status?: 'WAITING' | 'VERIFIED' | 'REJECTED';
    brand_verification_manager_dept?: string;
    brand_verification_manager_name?: string;
    brand_verification_manager_phone?: string;
  } | undefined>(undefined);
  const [editingBank, setEditingBank] = useState(false);
  const [editingPhone, setEditingPhone] = useState(false);

  useEffect(() => {
    const token = getCookie('token');
    if (!token) {
      window.location.replace('/login');
      return;
    }

    const getMyInfo = async () => {
      const request = await fetch(`${config.SERVER_ADDR}/auth/myinfo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await request.json();

      if (json.result) {
        setMyInfo(json.info);
      } else {
        alert('내 정보를 불러오는 중 오류가 발생했습니다.');
      }
    }
    getMyInfo().then();
  }, []);

  const editBank = async () => {
    if (editingBank) {
      const request = await fetch(`${config.SERVER_ADDR}/auth/bank`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bankName: myInfo?.bank_name ?? '',
          bankOwner: myInfo?.bank_owner ?? '',
          bankAccount: myInfo?.bank_account ?? '',
          ssn: myInfo?.ssn ?? '',
        }),
      });
      const json = await request.json();

      if (myInfo) {
        if (myInfo.ssn === '') setMyInfo({ ...myInfo, ssn: null });
        else if (myInfo.ssn !== null) setMyInfo({ ...myInfo, ssn: myInfo.ssn.substring(0, 6) + '-*******' });
      }

      if (!json.result && json.message === 'Invalid SSN') {
        alert('잘못된 주민등록번호 형식입니다.');
        return;
      }
    }

    setEditingBank(!editingBank);
  }
  const editPhone = async () => {
    if (editingPhone) {
      await fetch(`${config.SERVER_ADDR}/auth/phone`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone: myInfo?.phone ?? '',
        }),
      });
    }

    setEditingPhone(!editingPhone);
  }
  const brandRegister = async () => {
    const fileInput = document.querySelector('#iptBusinessRegistration') as HTMLInputElement;
    if (!fileInput || (fileInput.files?.length ?? 0) < 1) {
      alert('사업자등록증을 선택해주세요.');
      return;
    }
    const file = fileInput.files![0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(file.name)) {
      alert('이미지 파일만 업로드 가능합니다.');
      return;
    }

    if (myInfo?.brand_verification_manager_dept === undefined || myInfo.brand_verification_manager_dept === '') {
      alert('담당자 부서를 입력해주세요.');
      return;
    }
    if (myInfo?.brand_verification_manager_name === undefined || myInfo.brand_verification_manager_name === '') {
      alert('담당자 이름을 입력해주세요.');
      return;
    }
    if (myInfo?.brand_verification_manager_phone === undefined || myInfo.brand_verification_manager_phone === '') {
      alert('담당자 연락처를 입력해주세요.');
      return;
    }

    const fd = new FormData();
    fd.append('business_registration', file);
    fd.append('dept', myInfo.brand_verification_manager_dept);
    fd.append('name', myInfo.brand_verification_manager_name);
    fd.append('phone', myInfo.brand_verification_manager_phone);

    const request = await fetch(`${config.SERVER_ADDR}/auth/brand`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getCookie('token')}`,
      },
      body: fd,
    });
    const json = await request.json();

    if (json.result) {
      alert('브랜드 인증 신청이 완료되었습니다. 검토 후 빠른 시일 내에 연락드리겠습니다.');
      window.location.reload();
    } else {
      switch (json.message) {
        case 'You don\'t have any brand verification items':
          alert('브랜드 인증마크 신청권을 보유하고 있지 않습니다. 상점에서 구입해주세요.');
          break;
        case 'Brand verification has been requested':
          alert('이미 브랜드 인증을 신청했거나 검토중입니다.');
          break;
        default:
          alert(json.message);
      }
    }
  }

  return (
    <>
      <div className={"MyInfo-section__header"}>지갑 연동</div>
      <div className={"MyInfo-section"} style={{ textAlign: 'center' }}>
        <Kaikas />
        <div style={{ fontSize: '80%', marginTop: '5px' }}>
          [주의사항]<br />
          이 계정 아이디에서 최초 등록한 카이카스 지갑은 이후 변경이 불가하오니 신중하게 등록해주세요.<br />
          카이카스 지갑은 ID 1개당 지갑 1개만 연동 가능합니다.<br />
          다른 카이카스 지갑을 연동하려면, 로그아웃 후 새 구글 아이디를 생성 및 로그인하여 신규 계정으로 등록해주세요.
        </div>
      </div>
      <div className={"MyInfo-section__header"}>계좌 정보 <button onClick={() => editBank()}>{editingBank ? '저장하기' : '수정하기'}</button></div>
      <div className={"MyInfo-section"} style={{ marginLeft: '30px' }}>
        <div>예금주명</div>
        <div style={{ marginLeft: '10px', fontSize: '110%', marginTop: '5px', height: '26px' }}>
          {editingBank
            ? <input type={"text"} id={"iptBankOwner"} value={myInfo?.bank_owner} onChange={(e) => myInfo && setMyInfo({ ...myInfo, bank_owner: e.target.value })} />
            : (myInfo?.bank_owner ?? '등록되지 않음')}
        </div>
        <div style={{ marginTop: '15px' }}>은행</div>
        <div style={{ marginLeft: '10px', fontSize: '110%', marginTop: '5px', height: '26px' }}>
          {editingBank
            ? <input type={"text"} id={"iptBankName"} value={myInfo?.bank_name} onChange={(e) => myInfo && setMyInfo({ ...myInfo, bank_name: e.target.value })} />
            : (myInfo?.bank_name ?? '등록되지 않음')}
        </div>
        <div style={{ marginTop: '15px' }}>계좌 번호</div>
        <div style={{ marginLeft: '10px', fontSize: '110%', marginTop: '5px', height: '26px' }}>
          {editingBank
            ? <input type={"text"} id={"iptBankAccount"} value={myInfo?.bank_account} onChange={(e) => myInfo && setMyInfo({ ...myInfo, bank_account: e.target.value })} />
            : (myInfo?.bank_account ?? '등록되지 않음')}
        </div>
        <div style={{ marginTop: '15px' }}>주민등록번호</div>
        <div style={{ marginLeft: '10px', fontSize: '110%', marginTop: '5px', height: '26px' }}>
          {editingBank
            ? <input type={"text"} id={"iptSSN"} placeholder={"123456-1234567"} value={myInfo?.ssn ?? ''} onChange={(e) => myInfo && setMyInfo({ ...myInfo, ssn: e.target.value })} />
            : (myInfo?.ssn ?? '등록되지 않음')}
        </div>
      </div>
      <div className={"MyInfo-section__header"}>휴대폰 번호 <button onClick={() => editPhone()}>{editingPhone ? '저장하기' : '수정하기'}</button></div>
      <div className={"MyInfo-section"} style={{ marginLeft: '40px' }}>
        {editingPhone
          ? <input type={"text"} id={"iptPhone"} placeholder={"010-1234-1234"} value={myInfo?.phone} onChange={(e) => myInfo && setMyInfo({ ...myInfo, phone: e.target.value })} />
          : (myInfo?.phone ?? '등록되지 않음')}
      </div>
      <div className={"MyInfo-section__header"}>브랜드 인증마크</div>
      {myInfo?.brand_verification_status === 'WAITING' ? <div className={"MyInfo-section"} style={{ marginLeft: '30px' }}>브랜드 등록 신청이 완료되었습니다. 검토 후 빠른 시일 내에 연락드리겠습니다.</div> : (
        <div className={"MyInfo-section"} style={{ marginLeft: '30px' }}>
          {myInfo?.brand_verification_status === 'REJECTED' && '마지막 브랜드 인증 신청이 거절되었습니다. 다시 신청해 주세요.'}
          <div>사업자등록증</div>
          <div style={{ marginLeft: '10px', fontSize: '110%', marginTop: '5px', height: '26px' }}>
            {(!myInfo?.brand_verification_status && myInfo?.brand_verification_status !== 'VERIFIED')
              ? <input type={"file"} id={"iptBusinessRegistration"} accept={"image/*"} />
              : "제출완료"}
          </div>
          <div style={{ marginTop: '15px' }}>담당자 부서명</div>
          <div style={{ marginLeft: '10px', fontSize: '110%', marginTop: '5px', height: '26px' }}>
            {(!myInfo?.brand_verification_status && myInfo?.brand_verification_status !== 'VERIFIED')
              ? <input type={"text"} id={"iptBrandManagerDept"} onChange={(e) => myInfo && setMyInfo({ ...myInfo, brand_verification_manager_dept: e.target.value })} />
              : myInfo?.brand_verification_manager_dept}
          </div>
          <div style={{ marginTop: '15px' }}>담당자 이름</div>
          <div style={{ marginLeft: '10px', fontSize: '110%', marginTop: '5px', height: '26px' }}>
            {(!myInfo?.brand_verification_status && myInfo?.brand_verification_status !== 'VERIFIED')
              ? <input type={"text"} id={"iptBrandManagerName"} onChange={(e) => myInfo && setMyInfo({ ...myInfo, brand_verification_manager_name: e.target.value })} />
              : myInfo?.brand_verification_manager_name}
          </div>
          <div style={{ marginTop: '15px' }}>담당자 연락처</div>
          <div style={{ marginLeft: '10px', fontSize: '110%', marginTop: '5px', height: '26px' }}>
            {(!myInfo?.brand_verification_status && myInfo?.brand_verification_status !== 'VERIFIED')
              ? <input type={"text"} id={"iptBrandManagerPhone"} onChange={(e) => myInfo && setMyInfo({ ...myInfo, brand_verification_manager_phone: e.target.value })} />
              : myInfo?.brand_verification_manager_name}
          </div>
          {myInfo?.brand_verification_status !== 'VERIFIED' && (<button className={"btnBrandRegister"} onClick={() => brandRegister()}>등록</button>)}
        </div>
      )}
    </>
  );
}

export default MyInfo;
