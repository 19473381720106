import './Shop.css';
import {useEffect, useState} from "react";
import config from "../../config";
import {getCookie} from "../../Util/Cookie";

const _ITEM = {
  NICKNAME_CHANGE: {
    name: '닉네임 변경권',
    price: 4900,
    description: '닉네임을 변경할 수 있습니다.',
    image: '//via.placeholder.com/150',
  },
  LAND_BOOSTER: {
    name: '랜드 부스터권 (30일)',
    price: 9900,
    description: '30일간 소유 랜드에 드롭되는 아이템의 확률을 증가시킵니다.',
    image: '//via.placeholder.com/150',
  },
  SPEAKER: {
    name: '확성기',
    price: 300,
    description: '월드 전체에 메시지를 보낼 수 있습니다.',
    image: '//via.placeholder.com/150',
  },
  UNLIMITED_SPEAKER: {
    name: '무제한 확성기 (30일)',
    price: 29900,
    description: '30일간 월드 전체에 메시지를 무제한으로 보낼 수 있습니다.',
    image: '//via.placeholder.com/150',
  },
  UNLIMITED_NICK_CHANGE: {
    name: '무제한 닉네임 변경권',
    price: 19900,
    description: '닉네임을 무제한으로 변경할 수 있습니다.',
    image: '//via.placeholder.com/150',
  },
  BRAND_VALIDATE: {
    name: '브랜드 인증마크 신청권',
    price: 99900,
    description: '브랜드 인증마크를 신청할 수 있습니다.',
    image: '//via.placeholder.com/150',
  },
};

const formatDuration = (s: Date, c: Date) => {
  return `${s.getFullYear()}. ${(s.getMonth() + 1).toString().padStart(2, '0')}. ${s.getDate().toString().padStart(2, '0')} ~ ${c.getFullYear()}. ${(c.getMonth() + 1).toString().padStart(2, '0')}. ${c.getDate().toString().padStart(2, '0')}`;
}

const Shop = () => {
  const [items, setItems] = useState<{
    item_type: keyof typeof _ITEM;
    start_time: Date;
    end_time: Date;
    created: Date;
    count?: number;
  }[]>([]);

  useEffect(() => {
    const loadItems = async () => {
      const request = await fetch(`${config.SERVER_ADDR}/shop/list`, {
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
        },
      });
      const json = await request.json();

      if (json.result) {
        setItems(json.items.map((item: Record<string, unknown>) => ({
          ...item,
          item_type: item.item_type as keyof typeof _ITEM,
          start_time: new Date((item.start_time ?? 0) as string),
          end_time: new Date((item.end_time ?? 0) as string),
        })));
      } else {
        console.log(json.message);
      }
    };

    loadItems().then();
  }, []);

  const buyItem = async (item: string) => {
    const request = await fetch(`${config.SERVER_ADDR}/shop/buy`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getCookie('token')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        itemId: item,
      }),
    });
    const json = await request.json();

    if (json.result) {
      alert('구매가 완료되었습니다.');
      window.location.reload();
    } else {
      switch (json.message) {
        case 'Invalid item id':
          alert('잘못된 아이템 ID입니다. 다시 시도해주세요.');
          break;
        case 'User not found':
          alert('사용자 정보를 찾을 수 없습니다.');
          break;
        case 'Not enough point':
          alert('캐시 잔액이 부족합니다.');
          break;
        default:
          alert(json.message);
          break;
      }
    }
  }

  return (
    <div className={"Shop"}>
      <div className={"Shop__title"}>상점</div>
      <div className={"Shop__items"}>
        {Object.entries(_ITEM).map(([itemKey, item]) => (
          <div className={"Shop__items-item"}>
            <div className={"Item--image"}>
              <img src={item.image} alt={"상품 이미지"} />
            </div>
            <div className={"Item--name"}>{item.name}</div>
            <div className={"Item--price"}>{item.price.toLocaleString()}원</div>
            <div className={"Item--description"}>{item.description}</div>
            <div className={"Item--buy"}><button onClick={() => buyItem(itemKey)}>구매</button></div>
          </div>
        ))}
      </div>
      <div className={"Shop__title"}>보유 아이템</div>
      <div className={"Shop__items"}>
        {items.filter((item) => item.count !== 0).map((item) => (
          <div className={"Shop__items-item"}>
            <div className={"Item--image"}>
              <img src={_ITEM[item.item_type].image} alt={"상품 이미지"} />
            </div>
            <div className={"Item--name"}>{_ITEM[item.item_type].name}</div>
            <div className={"Item--price"}>{item.count ? `${item.count}개` : item.end_time.getTime() !== item.start_time.getTime() ? formatDuration(item.start_time, item.end_time) : ''}</div>
            <div className={"Item--description"}>{_ITEM[item.item_type].description}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Shop;
