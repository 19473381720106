import Header from "../Component/Header";
import {useEffect, useState} from "react";
import config from "../config";
import {getCookie, removeCookie} from "../Util/Cookie";

import './style/Mypage.css';
import MyLands from "../Component/MyLands";
import {Link, useLocation} from "react-router-dom";
import LeasedLands from "../Component/LeasedLands";
import MyInfo from "../Component/MyInfo";
import LeaseHistory from "../Component/LeaseHistory";
import Shop from "../Component/Shop";
import Deposit from "../Component/Deposit";
import Withdraw from "../Component/Withdraw";

const Mypage = () => {
  const [myInfo, setMyInfo] = useState<{
    nickname: string;
    nickname_changes: number;
  } | undefined>(undefined);
  const [editingNickname, setEditingNickname] = useState<boolean>(false);

  const { pathname } = useLocation();

  useEffect(() => {
    const token = getCookie('token');

    const getMyInfo = async () => {
      const request = await fetch(`${config.SERVER_ADDR}/auth/info`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await request.json();

      if (json.result) {
        setMyInfo(json.info);
      } else {
        removeCookie('token');
        window.location.replace('/login');
      }
    }

    if (token) {
      getMyInfo().then();
    } else {
      window.location.replace('/login');
    }
  }, []);

  const saveNickname = async () => {
    if (editingNickname) {
      const request = await fetch(`${config.SERVER_ADDR}/auth/nickname`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getCookie('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nickname: myInfo?.nickname ?? '',
        }),
      });
      const json = await request.json();

      if (json.result) {
        alert('닉네임이 변경되었습니다.');

        if (myInfo) {
          setMyInfo({
            ...myInfo,
            nickname_changes: myInfo.nickname_changes - 1,
          });
        }
      } else {
        switch (json.message) {
          case 'You already have this nickname':
            setEditingNickname(!editingNickname);
            break;
          case 'You don\'t have any nickname changes left':
            alert('남은 닉네임 변경권이 없습니다.');
            window.location.reload();
            break;
          case 'Nickname already taken':
            alert('이미 사용중인 닉네임입니다.');
            break;
          case 'Nickname must be between 3 and 20 characters':
            alert('닉네임은 3글자에서 20글자 사이여야 합니다.');
            break;
          case 'Nickname must only contain alphanumeric characters and Korean characters':
            alert('닉네임은 한글, 영어 또는 숫자만 포함할 수 있습니다.');
            break;
          default:
            alert('닉네임 변경 중 오류가 발생했습니다.');
        }
        return;
      }
    }
    setEditingNickname(!editingNickname);
  }

  return (
    <>
      <Header />
      {myInfo !== undefined && (
        <div className={"Mypage container"}>
          <div className={"Mypage__top"}>
            {myInfo?.nickname_changes > 0 && (
              <div className={"ChangeNicknameWrapper"}>
                <button onClick={() => saveNickname()} className={"btnChangeNickname"}>
                  {editingNickname ? "저장" : `수정 (${myInfo?.nickname_changes}회 남음)`}
                </button>
              </div>
            )}
            <div className={"Mypage__top-image"}>
              <img />
            </div>
            {editingNickname ? (
              <div className={"Mypage__top-info"}><input type={'text'} value={myInfo.nickname} onChange={(e) => setMyInfo({...myInfo, nickname: e.target.value})} /></div>
            ) : (
              <div className={"Mypage__top-info"}>{myInfo.nickname}</div>
            )}
          </div>
          <div style={{ marginTop: '50px' }}>
            <div className={"Mypage__MenuBar"}>
              <Link to={"/mypage"}><div className={`Mypage__Menu ${pathname === '/mypage' ? 'active' : ''}`}>임대 랜드</div></Link>
              <Link to={"/mypage/leased"}><div className={`Mypage__Menu ${pathname === '/mypage/leased' ? 'active' : ''}`}>임차 랜드</div></Link>
              <Link to={"/mypage/history"}><div className={`Mypage__Menu ${pathname === '/mypage/history' ? 'active' : ''}`}>거래 내역</div></Link>
              <Link to={"/mypage/info"}><div className={`Mypage__Menu ${pathname === '/mypage/info' ? 'active' : ''}`}>개인정보</div></Link>
              <Link to={"/mypage/point_deposit"}><div className={`Mypage__Menu ${pathname === '/mypage/point_deposit' ? 'active' : ''}`}>캐시 입금</div></Link>
              <Link to={"/mypage/point_withdraw"}><div className={`Mypage__Menu ${pathname === '/mypage/point_withdraw' ? 'active' : ''}`}>캐시 출금</div></Link>
              <Link to={"/mypage/shop"}><div className={`Mypage__Menu ${pathname === '/mypage/shop' ? 'active' : ''}`}>상점</div></Link>
            </div>
            <div className={"Mypage__content"}>
              {pathname === '/mypage' && <MyLands />}
              {pathname === '/mypage/leased' && <LeasedLands />}
              {pathname === '/mypage/history' && <LeaseHistory />}
              {pathname === '/mypage/info' && <MyInfo />}
              {pathname === '/mypage/point_deposit' && <Deposit />}
              {pathname === '/mypage/point_withdraw' && <Withdraw />}
              {pathname === '/mypage/shop' && <Shop />}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Mypage;
