import {useEffect, useState} from "react";
import './RecentLease.css';
import config from "../../config";

const RecentLease = () => {
  const [page, setPage] = useState(0);
  const [leases, setLeases] = useState<{
    owner_nickname: string;
    lease_nickname: string;
    floor: string;
    type: string;
    land: string;
    duration: number;
    price: number;
    created: string;
  }[]>([]);

  useEffect(() => {
    const getLeases = async () => {
      const request = await fetch(`${config.SERVER_ADDR}/land/recent`);
      const json = await request.json();

      if (json.result) {
        setLeases([...json.logs]);
      } else {
        alert('최근 임대 정보를 불러오는 중 오류가 발생했습니다.');
      }
    }

    getLeases().then();
  }, []);

  return (
    <>
      <div style={{ position: 'absolute', cursor: 'pointer', fontSize: '18px', marginTop: '50px', marginLeft: '-25px' }} onClick={() => setPage(Math.max(0, page - 1))}>〈</div>
      <div className={"RecentLease"}>
        {leases.map((lease, index) => (
          <div className={"RecentLease__container"} style={index === 0 ? { marginLeft: (20 + (-page * 199)) + 'px' } : {}}>
            <div className={"RecentLease__container__info"}>{lease.type === 'Arcade' ? '상가' : '아파트'} {lease.floor}층 {lease.land}호 ({lease.duration}개월)</div>
            <div className={"RecentLease__container__price"}>{lease.price.toLocaleString()}원</div>
            <div className={"RecentLease__container__price_month"}>({Math.floor(lease.price / lease.duration).toLocaleString()}원/월)</div><br />
            <div className={"RecentLease__container__owner"}>임대인 {lease.owner_nickname}<br />임차인 {lease.lease_nickname}</div>
            <div className={"RecentLease__container__date"}>
              {new Date(lease.created).getFullYear()}. {(new Date(lease.created).getMonth() + 1).toString().padStart(2, '0')}. {(new Date(lease.created).getDate()).toString().padStart(2, '0')}.
            </div>
          </div>
        ))}
      </div>
      <div style={{ position: 'absolute', cursor: 'pointer', fontSize: '18px', marginTop: '-109px', marginLeft: '1030px' }} onClick={() => setPage(Math.max(0, Math.min(page + 1, leases.length - 5)))}>〉</div>
    </>
  );
}

export default RecentLease;
