import Header from "../Component/Header";
import {useEffect, useState} from "react";
import {getCookie} from "../Util/Cookie";
import config from "../config";

import './style/NewLand.css';

const NewLand = () => {
  const [myNfts, setMyNfts] = useState<{
    id: string;
    attributes: {value: string, trait_type: string}[];
    image: string;
  }[]>([]);
  const [selectedNft, setSelectedNft] = useState<{
    id: string;
    attributes: {value: string, trait_type: string}[];
    image: string;
  } | undefined>(undefined);

  const [landPrice, setLandPrice] = useState<string>('0');
  const [leaseDuration, setLeaseDuration] = useState<string>('1');

  useEffect(() => {
    const getMyNfts = async () => {
      const token = getCookie('token');

      if (!token) {
        window.location.replace('/');
        return;
      }

      const request = await fetch(`${config.SERVER_ADDR}/auth/nfts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await request.json();

      if (json.result) {
        setMyNfts(json.nfts);
        if (json.nfts.length > 0) {
          setSelectedNft(json.nfts[0]);
        }
      } else {
        if (json.message === 'Wallet address not set') {
          alert('랜드 임대를 시작하기 위해서 Kaikas 지갑을 연동해 주세요.');
          window.location.replace('/mypage');
          return;
        }
        alert('NFT 정보를 불러오는 중 오류가 발생했습니다. 나중에 다시 시도해주세요.');
      }
    }

    const checkEligibility = async () => {
      const token = getCookie('token');

      if (!token) {
        window.location.replace('/');
        return;
      }

      const request = await fetch(`${config.SERVER_ADDR}/auth/myinfo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const json = await request.json();

      if (!json.result || !json.info.bank_account || !json.info.bank_name || !json.info.bank_owner || !json.info.phone || !json.info.ssn) {
        alert('랜드 임대를 시작하기 위해서 개인정보를 모두 입력해 주세요.');
        window.location.replace('/mypage/info');
        return false;
      }
      return true;
    }

    checkEligibility().then((result) => {
      if (result) {
        getMyNfts().then();
      }
    });
  }, []);

  const doApply = async () => {
    if (!('klaytn' in window)) {
      alert('랜드를 임대하기 위해서는 Kaikas 지갑이 설치된 PC에서 접속해야 합니다.');
      return;
    }

    const request = await fetch(`${config.SERVER_ADDR}/land/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('token')}`,
      },
      body: JSON.stringify({
        nftId: selectedNft?.id,
        leaseDuration: parseInt(leaseDuration),
        price: parseInt(landPrice),
      }),
    });
    const json = await request.json();

    if (json.result) {
      // Do Kaikas transaction
      if ('klaytn' in window) {
        // @ts-ignore
        const provider = window.klaytn;

        await provider.enable();
        const address = provider.selectedAddress;

        // @ts-ignore
        const caver = window.caver;
        const kip17 = new caver.klay.KIP17('0xff040fb888c49422b9c39dab40fccd77ad1db9df');
        await kip17.transferFrom(address, json.addr, selectedNft?.id, { from: address });

        alert('랜드 임대 추가가 정상적으로 완료되었습니다. 마켓플레이스에 표시되기까지는 수 초가 걸릴 수 있습니다.');
        window.location.replace('/mypage');
      } else {
        alert('Kaikas 지갑이 설치되어 있지 않습니다.');
        return;
      }
    } else {
      switch (json.message) {
        case 'Invalid lease duration':
          alert('임대 기간은 1개월 이상으로 설정해주세요.');
          break;
        case 'Invalid price':
          alert('잘못된 금액입니다.');
          break;
        default:
          alert('랜드 임대 중 오류가 발생했습니다. 나중에 다시 시도해주세요.');
          break;
      }
    }
  }

  return (
    <>
      <Header />
      <div className={"NewLand container"}>
        <div style={{ fontSize: '150%', marginTop: '40px', marginBottom: '20px' }}>임대 랜드 추가하기</div>
        <div className={"NewLand__image"}>
          <img src={selectedNft?.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')} alt={'랜드 이미지'} />
        </div>
        <div className={"NewLand__form"}>
          <div className={"NewLand__form-wrapper"}>
            <div className={"NewLand__form-title"}>랜드 선택</div>
            <div className={"NewLand__form-input"}>
              <select id={"selectNFT"} value={selectedNft?.id} onChange={(e) => setSelectedNft(myNfts.find(nft => nft.id === e.target.value))}>
                {myNfts.map((nft) => (
                  <option value={nft.id}>
                    {nft.attributes.find((attr) => attr.trait_type === 'Floor')?.value.replace('F', '')}층
                    {' '}
                    {nft.attributes.find((attr) => attr.trait_type === 'Land')?.value}호
                    {' '}
                    ({nft.attributes.find((attr) => attr.trait_type === 'Type')?.value === 'Arcade' ? '상가' : '아파트'} 랜드)
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={"NewLand__form-wrapper"}>
            <div className={"NewLand__form-title"}>임대 기간</div>
            <div className={"NewLand__form-input"}>
              <input type={"text"} id={"iptDuration"} inputMode={"numeric"} value={leaseDuration} onChange={(e) => setLeaseDuration(e.target.value)} />
              <div className={"NewLand__form-input-unit"}>개월</div>
            </div>
          </div>

          <div className={"NewLand__form-wrapper"}>
            <div className={"NewLand__form-title"}>가격</div>
            <div className={"NewLand__form-input"}>
              <input type={"text"} id={"iptPrice"} inputMode={"numeric"} value={landPrice} onChange={(e) => setLandPrice(e.target.value)} />
              <div className={"NewLand__form-input-unit"}>원</div>
            </div>
          </div>
        </div>
        <div className={"NewLand__submit"}>
          <button onClick={() => doApply()}>등록하기</button>
        </div>
      </div>
    </>
  );
}

export default NewLand;
